:root {
    --brown: #5a3c2e;
    --dark-text: #1a1a1a;
    --light-text: #e4e4e4;

    --success: #3b783b;
    --error: rgb(233, 22, 22);

    --light: #d8d3c8;
    --dark: #666666;
    --link: #15159e;
    --link-hover: rgb(96, 105, 8);
    
    --shadow: 0 2px 24px rgba(0, 0, 0, 0.2);
    --shadow-below: 0 4px 6px -2px grey
}

html {
    color: var(--dark-text);
    scroll-behavior: smooth;
}

main {
    min-height: calc(100vh - 80px - 48px);
}

.App {
    background: var(--light);
}

section > h2 {
    text-align: center;
    margin: 1.5rem 0;
    font-size: 2rem;
    font-weight: bold;
}


#about {
    margin-top: 3rem;
    padding-top: 4rem;
    padding-bottom: 2rem;
    clip-path: polygon(0 0, 100% 7.5rem, 100% 100%, 0% 100%);
    font-size: 1.4rem;
    background: var(--brown);
    color: var(--light-text);
}

@media (max-width: 50rem) {

    #about {
        clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 100%);
    }
}
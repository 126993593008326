* {
    box-sizing: border-box;
}

*:focus {
    outline: 2px solid black !important;
    outline-offset: 1px;
}

body {
  margin: 0;
  font-family: 'Poppins',  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

body.no-scroll {
    overflow: hidden;
}

h1 { font-size: 5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 2rem; }
h4 { font-size: 1.2rem; }
h5 { font-size: 1rem; }
h6 { font-size: .9rem; }

blockquote {
    margin: 24px 0 24px 24px;
    padding-left: 8px;
    border-left: 4px solid grey;
}

p {
    margin-bottom: .5rem;
}

@media only screen and (max-width: 50rem) {
    h1 {
        font-size: 4rem;
    }
}


.slide-in-left, .slide-in-right, .slide-in-down, .slide-in-up {
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
}
.slide-in-left { animation-name: slidein-left; }
.slide-in-right { animation-name: slidein-right; }
.slide-in-down { animation-name: slidein-down; }
.slide-in-up { animation-name: slidein-up; }


@keyframes slidein-left {
    from {
        transform: translateX(-3rem);
        opacity: 0%;
    }
    
    to {
        transform: unset;
        opacity: 100%;
    }
}

@keyframes slidein-right {
    from {
        transform: translateX(3rem);
        opacity: 0%;
    }
    
    to {
        transform: unset;
        opacity: 100%;
    }
}

@keyframes slidein-down {
    from {
        transform: translateY(-3rem);
        opacity: 0%;
    }
    
    to {
        transform: unset;
        opacity: 100%;
    }
}

@keyframes slidein-up {
    from {
        transform: translateY(3rem);
        opacity: 0%;
    }
    
    to {
        transform: unset;
        opacity: 100%;
    }
}